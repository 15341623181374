/* eslint-disable no-empty-pattern */
import { createStore } from 'vuex'
import apollo from '@/apollo/config'
import axios from 'axios'
import {
  getMe,
  getMyData,
  abstractGetUsers,
  getAbstracts,
  duplicateUserName,
  permittedAbstract,
  abstractTypeCount,
  getSpeakers,
  getGroups,
  getSingleGroup,
  getPopups,
  getSinglePopup,
  getInvitedUser,
  getNewsAndNotices,
  getNewsAndNotice,
  getSendEmails,
  getSingleUser,
  getMailTemplates,
  getMailTemplate,
  getPayLogs,
  getRegistrationLists,
  getSingleRegist,
  getSponsors,
  getExcelSponsors,
  getSingleSponsor,
  checkDuplicateRegister,
  validateCheckPayUrl,
  allNewsNoticeLists,
  getMemoLists,
} from '@/apollo/query'
import {
  register,
  login,
  updateUser,
  createAbstract,
  updateAbstract,
  deleteMultipleAbstract,
  createSpeaker,
  updateSpeaker,
  changeUserGroup,
  sendNewEmail,
  changeUserPasswordInFind,
  createPopup,
  updatePopup,
  createGroup,
  updateGroup,
  deleteMultipleGroups,
  deleteMultiplePopup,
  createNewsAndNotice,
  updateNewsAndNotice,
  deleteMultipleBbs,
  deleteMultipleSpeaker,
  deleteMultipleInvitedUser,
  createMailTemplate,
  updateMailTemplate,
  deleteMultipleMailTemplates,
  createResigtration,
  createPayment,
  updateResigtration,
  createSponsor,
  updateSponsor,
  deleteMultipleSponsor,
  changePayStatusAtResult,
  deleteMultipleRegister,
  checkupRegistpayment,
  forcePaymentCheck,
  createMemo,
  updateMemo,
} from '@/apollo/mutation'

import { adminStore } from '@/store/adminStore'

const TOKENNAME = 'mcircle-ksp'

export default createStore({
  state: {
    allowed_id: [
      'pfhdsf@protect.ne.kr',
      'hrl0324@mcircle.biz',
      'ch.yoo@mcircle.biz',
      'rladkfka3326@gmail.com',
      'mj.jeong@mcircle.biz',
      'splash@protect.ne.kr',
      'kspwon@kams.or.kr',
      'yunsy@mcircle.biz',
    ],
    reg_show_tabs: [0, 1, 2],
    meData: {},
    loading: false,
    window_size: {
      x: 0,
      y: 0,
      isMobile: false,
    },
    main_nav: '',
    main_nav_over: '',
    sub_nav: '',
    sub_nav_over: '',
    frame_nav: '',
    frame_nav_over: '',
    schedule_id: '',
    deadline: {
      abstract: '',
    },
    nav_menu: [
      {
        title: 'ksp2024',
        route_name: 'introductions',
        sub_menu: [
          { title: 'welcome_message', route_name: 'introduction_welcome' },
          { title: 'overview', route_name: 'introduction_overview' },
          { title: 'committees', route_name: 'introduction_committees' },
          // { title: 'past_congress', route_name: 'introduction_congress' },
          { title: 'history', route_name: 'introduction_history' },
        ],
      },
      {
        title: 'program',
        route_name: 'programs',
        sub_menu: [
          { title: 'picforum', route_name: 'program_picforum' },
          { title: 'program_at_glance', route_name: 'program_glance' },
          { title: 'scientific_program', route_name: 'program_scientific' },
          { title: 'invited_speaker', route_name: 'program_invited' },
          // { title: 'social_program', route_name: 'program_social' },
        ],
      },
      {
        title: 'call_for_abstract',
        route_name: 'abstracts',
        sub_menu: [
          { title: 'abstract_guidelines', route_name: 'abstract_guideline' },
          { title: 'abstract_submission', route_name: 'abstract_submission' },
          { title: 'abstract_presentation_guideline', route_name: 'present_guideline' },
          // { title: 'abstract_speaker_upload', route_name: 'present_upload' },
          { title: 'abstract_awards', route_name: 'abstract_awards' },
        ],
      },
      {
        title: 'registration',
        route_name: 'registrations',
        sub_menu: [
          { title: 'registration_kr', route_name: 'registration_korean' },
          { title: 'registration_overseas', route_name: 'registration_foreigner' },
          { title: 'hotel_reservation', route_name: 'custom-hotel' },
        ],
      },
      {
        title: 'sponsorship',
        route_name: 'sponsorships',
        sub_menu: [
          { title: 'sponsors', route_name: 'sponsor_view' },
          { title: 'sponsorship_apply', route_name: 'sponsor_apply' },
          { title: 'floor_plan', route_name: 'sponsor_floor' },
        ],
      },
      {
        title: 'information',
        route_name: 'informations',
        sub_menu: [
          { title: 'news_and_notices', route_name: 'info_news' },
          // { title: 'about_seoul', route_name: 'about_seoul' },
          { title: 'general_information', route_name: 'info_general' },
          { title: 'venue', route_name: 'info_venue' },
          // { title: 'gallery', route_name: 'info_gallery' },
          { title: 'download_center', route_name: 'info_download' },
        ],
      },
    ],
    auth_menu: {
      title: 'ksp2024',
      route_name: 'auth',
      sub_menu: [
        { title: 'signup', route_name: 'signup' },
        { title: 'login', route_name: 'login' },
        { title: 'findPassword', route_name: 'find' },
        { title: 'sitemap', route_name: 'sitemap' },
      ],
    },

    certificated_menu: {
      title: 'mypage',
      route_name: 'mypage',
      sub_menu: [
        { title: 'personal_information', route_name: 'my_info' },
        { title: 'my_abstract', route_name: 'my_abstract' },
        { title: 'my_registration', route_name: 'my_registration' },
        { title: 'sitemap', route_name: 'lg_sitemap' },
      ],
    },

    success_dialog: {
      open: false,
      title: '',
      text: '',
      modalIcon: 'success',
    },
    error_dialog: {
      open: false,
      title: '',
      text: '',
      modalIcon: 'error',
    },
  },
  getters: {},
  mutations: {
    meData(state, payload) {
      state.meData = payload
    },

    checkRegShow(state, payload) {
      if (payload == 'Korea') {
        state.reg_show_tabs = [0, 2]
      } else {
        state.reg_show_tabs = [1, 2]
      }
    },
  },
  actions: {
    register({ commit }, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({ mutation: register, variables: input })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    duplicateUserName({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: duplicateUserName,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    abstractGetUsers({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: abstractGetUsers,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getAbstracts({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getAbstracts,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })

          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getMe({ commit }) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getMe,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            if (data.me.delete) {
              localStorage.removeItem(TOKENNAME)
              commit('meData', {})
            } else {
              commit('meData', data.me)
              commit('checkRegShow', data.me.country)
            }
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getMyData({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getMyData,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    login({ commit }, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({ mutation: login, variables: input })
          .then(({ data }) => {
            if (data.login.user.delete) {
              localStorage.removeItem(TOKENNAME)
              commit('meData', {})
            } else {
              localStorage.setItem(TOKENNAME, data.login.jwt)
              commit('meData', data.login.user)
            }
            resolve(data.login.user)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateUser({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateUser,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createAbstract({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createAbstract,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    upload({}, input) {
      return new Promise((resolve, reject) => {
        var formData = new FormData()
        formData.append('files', input.files)
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
          },
        }
        axios
          .post(import.meta.env.VUE_APP_BACKEND_URL + '/upload', formData, config)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    uploadFree({}, input) {
      return new Promise((resolve, reject) => {
        var formData = new FormData()
        formData.append('files', input.files)
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        axios
          .post(import.meta.env.VUE_APP_BACKEND_URL + '/upload', formData, config)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    authcodeCheck({}, input) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            import.meta.env.VUE_APP_BACKEND_URL +
              `/checkAuth?email=${input.email}&authCode=${input.authCode}`,
          )
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getColumnKeys({}, input) {
      return new Promise((resolve, reject) => {
        axios
          .get(import.meta.env.VUE_APP_BACKEND_URL + `/column_list?table=${input.table}`)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    permittedAbstract({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: permittedAbstract,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateAbstract({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateAbstract,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    abstractTypeCount({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: abstractTypeCount,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createSpeaker({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createSpeaker,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getSpeakers({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSpeakers,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateSpeaker({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateSpeaker,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    changeUserGroup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: changeUserGroup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    sendNewEmail({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: sendNewEmail,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    changeUserPasswordInFind({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: changeUserPasswordInFind,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getGroups({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getGroups,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getSingleGroup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSingleGroup,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getPopups({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getPopups,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getSinglePopup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSinglePopup,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createPopup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createPopup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updatePopup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updatePopup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createGroup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createGroup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateGroup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateGroup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    deleteMultipleGroups({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleGroups,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    deleteMultiplePopup({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultiplePopup,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getInvitedUser({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getInvitedUser,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createNewsAndNotice({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createNewsAndNotice,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateNewsAndNotice({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateNewsAndNotice,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateNewsAndNoticePublic({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateNewsAndNotice,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getNewsAndNotices({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getNewsAndNotices,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getNewsAndNotice({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getNewsAndNotice,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleBbs({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleBbs,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getSendEmails({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSendEmails,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getSingleUser({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSingleUser,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    deleteMultipleSpeaker({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleSpeaker,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleInvitedUser({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleInvitedUser,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getMailTemplates({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getMailTemplates,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createMailTemplate({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createMailTemplate,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getMailTemplate({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getMailTemplate,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateMailTemplate({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateMailTemplate,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleMailTemplates({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleMailTemplates,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleAbstract({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleAbstract,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getPayLogs({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getPayLogs,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createResigtration({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createResigtration,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createPayment({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createPayment,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getRegistrationLists({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getRegistrationLists,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getSingleRegist({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSingleRegist,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateResigtration({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateResigtration,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createSponsor({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createSponsor,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createSponsorFree({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createSponsor,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateSponsor({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateSponsor,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getSponsors({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSponsors,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })

          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getExcelSponsors({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getExcelSponsors,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })

          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getSingleSponsor({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getSingleSponsor,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleSponsor({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleSponsor,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    checkDuplicateRegister({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: checkDuplicateRegister,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    cancelPayment({}, input) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
          },
        }
        axios
          .post(import.meta.env.VUE_APP_BACKEND_URL + '/cancel', input, config)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    closeTrade({}, input) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
          },
        }
        axios
          .get(
            import.meta.env.VUE_APP_BACKEND_URL + `/close_trade_id?user_email=${input.user_email}`,
            config,
          )
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    changePayStatusAtResult({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: changePayStatusAtResult,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteMultipleRegister({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: deleteMultipleRegister,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    validateCheckPayUrl({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: validateCheckPayUrl,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    checkupRegistpayment({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: checkupRegistpayment,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    allNewsNoticeLists({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: allNewsNoticeLists,
            variables: input,
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    forcePaymentCheck({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: forcePaymentCheck,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getMemoLists({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .query({
            query: getMemoLists,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createMemo({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: createMemo,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateMemo({}, input) {
      return new Promise((resolve, reject) => {
        apollo.clients['defaultClient']
          .mutate({
            mutation: updateMemo,
            variables: input,
            context: {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem(TOKENNAME),
              },
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  modules: { adminStore },
})
