import store from '@/store'
import router from '@/router'

/** phone number check in validation for KOR with hyphen or without hyphen.
 * true is passed false is not passed.
@param {string} phone_number input phone number from variable.
@returns {boolean} true / false in regex check.
@author 조재호
*/
function checkPhoneKr(phone_number) {
  const phone_regex_kr_13 = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
  const phone_regex_kr_11 = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
  if (phone_number.length <= 11) {
    return phone_regex_kr_11.test(phone_number)
  } else if (phone_number.length <= 13) {
    return phone_regex_kr_13.test(phone_number)
  } else {
    return false
  }
}

/**Checkup number in the validation in side
@param {string | integer} contents input number from variable
@returns {boolean} true / false in regex check.
@author 조재호
*/
function checkNumber(contents) {
  const number_check = /^[0-9][0-9]*[0-9]$/
  return number_check.test(contents)
}

function checkKorean(contents) {
  const korean_check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
  return korean_check.test(contents)
}

/**phone number check in validation global with hyphen or without hyphen
@param {string} phone_number phone number variable
@author 조재호
*/
function checkPhoneGl(phone_number) {
  const phone_regex_gl = /^[0-9][0-9\-]*[0-9]$/
  return phone_regex_gl.test(phone_number)
}

/**the international dial code in global with start by "+"
@param {string} glboal_code global dial code variable
@author 조재호
*/
function checkGlobalCode(global_code) {
  const global_regex = /^\+[0-9]*$/
  return global_regex.test(global_code)
}

/**name validate check on the input field 
@param {string} name type the name value from the input field
@param {string} type type the "korea" or "overseas" by type case in korea check only Korean character
@returns {boolean} true / false in regex check.
@author 조재호
*/
function checkName(name, type) {
  if (type === 'korea') {
    const korean = /^[가-힣\s]*$/
    return korean.test(name)
  } else if (type === 'overseas') {
    const english = /^[a-zA-Z\s\-]*$/
    return english.test(name)
  } else {
    return false
  }
}

/**affiliation check onth input field
@param {string} affil_name affiliation name in the input field
@param {string} type type on "korea" or "overseas"
@returns {boolean} true or false
@author 조재호
*/
function checkAffiliation(affil_name, type) {
  if (type === 'korea') {
    const korean = /^[가-힣()0-9\s]*$/
    return korean.test(affil_name)
  } else if (type === 'overseas') {
    const english = /^[a-zA-Z()0-9\s]*$/
    return english.test(affil_name)
  }
}

/**password check up with no-special character use in this place
@param {string} pre_password password for user data
@author 조재호
*/
function checkPassword(pre_password) {
  const password_regex_special = /[<>`~!@#$%^&*|\\\'\";:\/?]/gi
  return password_regex_special.test(pre_password)
}

/**email check in validation global with '@' mark and '.com'
 * true is passed false is not passed.
@param {string} email_address input email address from varaiable
@returns {boolean} true / false in regex check
@author 조재호
*/
function checkEmail(email_address) {
  const email_regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
  )
  const email_regext2 = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  let result = email_regex.test(email_address)
  return result
}

/**contents blank validation in global with '' or  ' '
 * true is passed false is not passed.
@param {string} contents contents which you want to check blank
@returns {boolean} true / false in validation check
@author 조재호
*/
function checkBlank(contents) {
  if (!String(contents)) {
    return false
  }
  const blank_check = String(contents).trim().replace(/\s/g, '')
  return blank_check === '' ? false : true
}

/**contents which URL domain validation
@param {string} contents which needs to validate 
@returns {boolean} true/false in validate check
@author 조재호 
*/
function checkUrlDomain(contents) {
  const domain_check =
    /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/gi
  return domain_check.test(contents)
}

/**routing check validation when refresh contents in page
@param {string} type type on page(diffrent static data set in store check in store)
@author 조재호
*/
function checkRouteRefresh(type) {
  if (type === 'auth') {
    store.state.main_nav = store.state.auth_menu
    for (let index = 0; index < store.state.auth_menu.sub_menu.length; index++) {
      if (store.state.auth_menu.sub_menu[index].route_name === router.currentRoute.value.name) {
        store.state.sub_nav = store.state.auth_menu.sub_menu[index]
        store.state.frame_nav = index
        return
      }
    }
    return
  }

  if (type === 'certificated') {
    store.state.main_nav = store.state.certificated_menu
    for (let index = 0; index < store.state.certificated_menu.sub_menu.length; index++) {
      if (
        store.state.certificated_menu.sub_menu[index].route_name === router.currentRoute.value.name
      ) {
        store.state.sub_nav = store.state.certificated_menu.sub_menu[index]
        store.state.frame_nav = index
        return
      }
    }
    return
  }

  if (type === 'main') {
    for (let index = 0; index < store.state.nav_menu.length; index++) {
      if (store.state.nav_menu[index].route_name === router.currentRoute.value.matched[1].name) {
        const idx = store.state.nav_menu[index].sub_menu.findIndex(
          (el) => el.route_name === router.currentRoute.value.name,
        )
        store.state.main_nav = store.state.nav_menu[index]
        store.state.sub_nav = idx > -1 ? store.state.nav_menu[index].sub_menu[idx] : ''
        store.state.frame_nav = idx > -1 ? idx : ''
        return
      }
    }
    return
  }
  if (type === 'admin') {
    for (let index = 0; index < store.state.adminStore.left_nav_menu.length; index++) {
      if (
        store.state.adminStore.left_nav_menu[index].route_name ===
        router.currentRoute.value.matched[2].name
      ) {
        const idx = store.state.adminStore.left_nav_menu[index].sub_menu.findIndex(
          (el) => el.route_name === router.currentRoute.value.name,
        )
        store.state.adminStore.main_nav = store.state.adminStore.left_nav_menu[index]
        store.state.adminStore.selectedSubMenus =
          store.state.adminStore.left_nav_menu[index].sub_menu[idx]
        return
      }
    }
    return
  }
}

function checkBizNumber(contents) {
  let string_data = String(contents)
  if (string_data.length > 12) {
    return false
  }
  const biz_regex = /^[0-9][0-9\-]*[0-9]$/
  const biz_form = /[0-9]{3}\-[0-9]{2}\-[0-9]{5}/
  if (string_data.includes('-')) {
    return biz_regex.test(string_data) && biz_form.test(string_data)
  }
  if (string_data.length !== 10) {
    return false
  } else {
    return biz_regex.test(string_data)
  }
}

function checkRegistDate() {
  const today = Number(
    new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' })).getTime(),
  )
  const start = Number(
    new Date(
      new Date('2024-05-01T00:00:00').toLocaleString('en-US', { timeZone: 'Asia/Seoul' }),
    ).getTime(),
  )
  const last = Number(
    new Date(
      new Date('2024-09-29T23:59:59').toLocaleString('en-US', { timeZone: 'Asia/Seoul' }),
    ).getTime(),
  )
  return Boolean(today >= start && today <= last)
}

const globalValidation = {
  checkPhoneKr,
  checkPhoneGl,
  checkGlobalCode,
  checkEmail,
  checkBlank,
  checkRouteRefresh,
  checkPassword,
  checkName,
  checkAffiliation,
  checkKorean,
  checkNumber,
  checkUrlDomain,
  checkBizNumber,
  checkRegistDate,
}

export default globalValidation
